import './features.css';

const headerStyle = {
    backgroundImage: `url("${process.env.PUBLIC_URL}/images/features/bsc-building-2.jpg")`,
    objectFit: "none",
    position: "relative",
    width: "100%",
    objectPosition: "50% 85%",
  };

function Features() {
    return (<div>
        <div className="features-container" style={headerStyle}>
            <div className="middle-section">
                <div className="banner-container split-left is-12-mobile">
                    <div className="middle-div is-shadowless is-3 columns">
                        <div className="middle-text column is-8">
                            <h2 class="bold-text">High Performance Artificial Intelligence</h2>
                            <h3 class="subtext">Research Group @ Barcelona Supercomputing Center</h3>
                        </div>
                        <div className="middle-images column is-4 columns is-mobile is-vcentered">
                            <img id="hpc" src={`${process.env.PUBLIC_URL}/images/hpai_logo_grad.png`} alt="hpai-logo.png"
                                 className="column banner-image is-6" />
                            <img id="bsc" src={`${process.env.PUBLIC_URL}/images/bsc-logo.png`} alt="BSC-logo.png"
                                 className="column banner-image is-6" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer home-footer">
            <center>
            <div className="footer-container">
                <div className="wall-of-text is-size-6-mobile" style={{"text-align": "center"}}>
                    <h4 style={{"margin": "1em"}}>
                        The HPAI team is composed of highly committed researchers and engineers, focused on advancing the state-of-the-art in Artificial Intelligence research and innovation, while working towards the global well-being. We also try to have fun while doing so.
                    </h4>

                </div>

            </div>
            </center>
        </div>

        <div className="home-footer-2" style={{ opacity: 1, backgroundColor: 'white', "text-align": "center"}}>
            <h4>
                Check our most recent AI contributions below, or an extended list on the <a href="https://hpai.bsc.es/research"> research page</a>.
            </h4>
        </div>
        </div>
);
}

export default Features;





